interface Document {
	type: string;
	uid: string;
}

export const linkResolver = (doc: Document) => {
	if (doc.type === "home_page") {
		return "/";
	} else if (doc.type === "page" || doc.type === "article_home") {
		return `/${doc.uid}`;
	} else if (doc.type === "employee") {
		return `/team/${doc.uid}`;
	} else if (doc.type === "post") {
		return `/article/${doc.uid}`;
	}
	return "/";
};
