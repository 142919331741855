/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { registerLinkResolver } from "gatsby-source-prismic-graphql";

import { linkResolver } from "./src/utilities/linkResolver";

registerLinkResolver(linkResolver);
