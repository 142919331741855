module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"bridgelawgroup","accessToken":"MC5YbmFKQXhJQUFDY0FIakdL.K--_ve-_vWXvv73vv73vv73vv71x77-9AQfvv70p77-977-9O--_vTFf77-9A1Au77-977-9YF7vv73vv71H77-9","pages":[{"type":"Employee","match":"/team/:uid","path":"/team","component":"/opt/build/repo/src/templates/employee.tsx"},{"type":"Post","match":"/article/:uid","path":"/article","component":"/opt/build/repo/src/templates/article.tsx"},{"type":"Page","match":"/:uid","path":"/companies","component":"/opt/build/repo/src/templates/page.tsx"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
